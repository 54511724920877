<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новая подсказка"
        create
        url-update="/hint/update/:id"
        url-back="/hint"
    >
        <hint-form type="job-hint" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import HintForm from '@/components/forms/HintForm.vue'
export default {
    name: 'HintCreate',
    components: { FormView, HintForm }
}
</script>